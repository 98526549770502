.query-bar-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    min-height: 70px;
    border-radius: 60px !important;
    padding: 15px;
}

.query-bar-input {
    margin-left: 10px;
    flex: 1;
    padding: 20px;
}