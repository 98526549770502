.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-image: url('../../public/Login1.jpg');
    background-size: 100% 100%; /* Stretch to fit the content area */
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
  
  .login-form {
    flex: 1;
    padding: 0px;
    max-width: 375px;
    height: 400px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin: 5px;
  }
  
  
  .Deloitte-Logo {
    position: absolute;
    top: 5px;
    right: 10px;
  }
  
  .box {
    flex: 1;
    padding: 20px;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    border: 1px solid #ddd;
    margin: 10px;
    height: fit-content;
    max-width: 375px;
    height: 300px;
  }
  
  .h2-login {
    font-size: 24px;
    color: #86BC25;
    margin-bottom: 10px;
  }
  
  ul {
    list-style: disc;
    padding-left: 20px;
  }
  
  .form-group {
    margin: 10px 0;
    align-items: flex-start;
    alig-content: start;
  }
  
  .label-login {
    text-align: start;
    padding: 10px;
    display: block;
    font-weight: bold;
    color: gray;
  }
  
  .input-login {
    width: 90%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    outline: none;
    color: black;
  }
  
.login-button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .login-button:hover {
    background-color: #0056b3;
  }
  
  a {
    color: #007bff;
    text-decoration: none;
  }