
.feedback-text-box {
    padding: 15px;
    padding-bottom: 0px;
}

.feedback-send-button {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 10px;
}

