body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dropzone {
  text-align: center;
  padding: 20px;
  /* background-color: nac */
  border: 3px darkslategrey dashed;
  /* width: 100%;
  margin: auto; */
}

.btn {
  border: none;
  text-align: center;
  /* background-color: rgb(218, 216, 216); */
  height: 50px;
  border-radius: 12px;
  color: black;
  font-weight: bold;
  transition-duration: 0.6s;
}

/* .btn:hover {
  background-color: white;
  color: aliceblue;
} */

.file-list {
  /* border: 3px dotted black; */
  display: flex !important;
  flex-wrap: wrap;
  width: auto;
  padding: 10px 20px;
  margin: 20px 30px;
  /* border: 3px dotted black; */
}

