.Doc-Management-Backdrop {
    color: #fff
}

.Doc-Management-Stack-Row {
    margin-right: 10px;
    margin-left: 10px;
    margin-top: 120px;
    margin-bottom: 10px;
    height: 100%;
    align-content: center;
    justify-content: center;
}

.Doc-Management-Stack-Column {
    margin-right: 20px;
    margin-left: 20px;
    align-items: center;
    justify-content: center;
}

.Doc-Management-Box-Table {
    text-align: center;
    align-items: center;
    justify-content: center;
    display: block;
}

.Doc-Management-Button-Update {
    width: 300px;
    font-size: 1.2rem;
}

.Doc-Management-Dialog {
    position: absolute;
    left: 50%;
}

.pdfViewer {
    flex: 1;
    overflow: auto;
    justify-content: center;
    min-width: 35vw;
    margin-right: 10vh;
    height: 80vh;
}
 
  
  
  