.App {
  text-align: center;
  height: 100vh; 
  display: flex
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-div {
  min-height: 100vh; 
}

.App-header {
  background-color: lightgray;
  /* min-height: 100vh; */
  display: flex-start;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-drawer {
  min-height: 100vh; 
  width: fit-content;
  flex-shrink: 0;
  overflow-x: hidden;
}

.App-HistoryDrawer {
  min-height: 100vh; 
  width: fit-content;
  flex-shrink: 0;
  overflow-x: hidden;
  z-index: 12;
}

.App-drawer-button-closed {
  position: absolute;
  top: 20px;
  left: 10px;
}

.App-HistoryDrawer-button-closed {
  position: absolute;
  bottom: 20px;
  right: 10px;
}

.Deloitte-Logo {
  position: absolute;
  top: 5px;
  right: 10px;
}

.App-main-content {
  /* background-color: rgb(40 40 173); */
  /* min-height: 100%;  */
  min-height: 100vh; 
  width: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* margin: 80px; */
  flex: 1 1 0%
}


.App-main {
  background-color: white;
  width: 100%;
  display: flex;
}


.dropzone {
  text-align: center;
  border: 3px lightgrey dashed;
  margin-bottom: 10px;
  /* margin: auto; */
}

.delete-confirmation {
  position: fixed;
  top: 40vh;
  width: 20%;
  z-index: 100;
  overflow: hidden;
  margin-left: -10%;
  margin-top: 0;
}

.reset-confirmation {
  position: fixed;
  top: 40vh;
  width: 20%;
  z-index: 100;
  overflow: hidden;
  /* align-items: center; */
  margin-left: 37%;
  /* margin-right: 40%; */
  margin-top: 0;
}

.delete-confirmation-history-drawer {
  position: fixed;
  top: 40vh;
  width: 20%;
  z-index: 100;
  overflow: hidden;
  margin-left: -45%;
  margin-top: 0;
}

.dialogue-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(1,1,1, 0.4);
}

.upload-with-attributes {
  position: fixed;
  top: 5vh;
  width: 30%;
  z-index: 100;
  overflow: hidden;
  margin-left: -15%;
  margin-top: 0;
}

.chat-creation {
  position: fixed;
  top: 30vh;
  width: 20%;
  z-index: 100;
  overflow: hidden;
  margin-left: -50%;
  margin-top: 0;
}