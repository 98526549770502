.vector-search-page {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
}

.content-container {
    width: 100%;
    width: 1200px; /* Set a fixed width for the content */
    display: flex;
    justify-content: space-between; /* Adjust as needed */
}

.loading-container {
    display: flex;
    flex-direction: column;
}

.vector-search-container {
    margin-top: 10px;
    display: flex;
    flex: 1; /* Use flex to fill remaining width */
    overflow: hidden;
    align-content: center;
    align-items: flex-start;
    justify-content: center;
    overflow: visible;
}

.search-input-container {
    max-width: 1200px;
    width: 100%;
    padding: 1px;
    margin: 1px;
}

.search-input-paper {
    padding: 2px 4px;
    display: flex;
    align-items: center;
    max-width: 500px;
    width: 100%;
    min-height: 70px;
    border-radius: 20px !important;
}


.search-input {
    margin-left: 1px;
    flex: 1;
}

.loading-container {
    text-align: center;
    position: relative;
    right: 1vw;
}

.loading-ball {
    width: 20px;
    height: 20px;
    background-color: #42db34;
    border-radius: 50%;
    animation: bounce 1s infinite;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-20px);
    }
    60% {
        transform: translateY(-10px);
    }
}